import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './App.css';

function App() {

    const [name, setName] = useState('');
    const [lname, setLName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const formData = ({name, phone, email, subject, message})
    const [formResponse, setFormResponse] = useState('');
    const handleSubmit = async () => {

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "X-Requested-With": "*",
            "Access-Control-Allow-Methods": "OPTIONS,POST",
            "Access-Control-Allow-Credentials": true,
        };

        const data = {
            "host": "mediquotes",
            "first_name": name,
            "last_name": lname,
            "email": email,
            "phone": phone,
            "address": '',
            "state": state,
            "zipcode": zipcode,
            "dob": "",
            "lead_id": document.getElementById('leadid_token').value,
            "ip_address": '',
            "remarks": message
        };
        console.log("Form DATA: " + JSON.stringify(data));

        const url = 'https://react.mediquotes.us/process_api.php';
        //const url = 'http://localhost/process_api.php';

        const result = await axios.post(url, data, {
            headers: headers
        });

        console.log(result.data.status);
        if (result.data.status == 'fail') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-danger">' + result.data.message + '</div>';
        }
        if (result.data.status == 'success') {
            document.getElementById("ajaxResponse").innerHTML = '<div class="alert alert-success">' + result.data.message + '</div>';
            const timeout = setTimeout(() => {
                window.location.replace('/');
            }, 2000);
        }
    };

    (function () {
        var s = document.createElement('script');
        s.id = 'LeadiDscript_campaign';
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://create.lidstatic.com/campaign/4d4053b0-c3a2-1cb4-9039-446a9e72e404.js?snippet_version=2';
        var LeadiDscript = document.getElementById('LeadiDscript');
        LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();

    return (
        <>
            <header id="home" className="hero-area-2">
                <div className="overlay"></div>
                <div className="navigation">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg"><a className="navbar-brand" href="index-2.html"><i
                            className="lni lni-pulse css-1n3ub8g"></i>Mediquotes</a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation"><span
                                className="toggler-icon"></span> <span className="toggler-icon"></span> <span
                                className="toggler-icon"></span></button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active"><a className="page-scroll" href="#home">Home</a>
                                    </li>
                                    <li className="nav-item"><a className="page-scroll" href="#features">Benefits</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="contents text-left" style={{paddingBottom: "30px"}}>
                                <h2>Medicare Plan Quote</h2>
                                <p>If you are new to Medicare, aging in, or recently moved, our licensed insurance
                                    agents are standing by to help review your Medicare plan options.</p>
                            </div>
                            <div className="contact-info"><i className="lni-phone"></i>
                                <h5 style={{color: "#fff"}}>Phone</h5>
                                <p style={{color: "#fff"}}>Phone Number: (561) 589 2506<br/>
                                    <a href="tel:711">TTY: 711</a></p>
                                <br/>
                                <i className="lni-map"></i>
                                <h5 style={{color: "#fff"}}>Working Hours</h5>
                                <p style={{color: "#fff"}}>10AM - 7PM EST Monday-Friday</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="myform contents" style={{
                                background: "rgba(255,255,255,0.9)", padding: "15px 30px 30px 30px", marginTop: "125px",
                                marginBottom: "10px"
                            }}>
                                <h1 style={{fontSize: "27px", paddingBottom: "0px"}}>Get a no obligation Quote</h1>
                                <span style={{
                                    float: "left",
                                    fontSize: "14px",
                                    color: "#313",
                                    marginBottom: "17px",
                                    width: "100%"
                                }}>* There is no obligation to enroll!</span>

                                <form onSubmit={handleSubmit} action="#" method="post" className="contact-form"
                                      name='contact-form' id='contact-form'>
                                    <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-xs-12">
                                            <div className="form-group"><i className="contact-icon fa fa-phone"></i>
                                                <input type="number" className="form-control capture_number"
                                                       id="phone_number" name="phone_number"
                                                       placeholder="Phone Number" required="" value={phone}
                                                       onChange={e => setPhone(e.target.value)}
                                                       data-error="Please enter your number"/>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-xs-12">
                                            <p>
                                                <label style={{fontSize: "12px", fontWeight: "normal", color: "#313"}}>
                                                    <input type="hidden" id="leadid_tcpa_disclosure"/>
                                                    [By clicking the "Get No Obligation Quote" button below, I
                                                    agree to this website's <a href="termsofservices.html">terms
                                                    of service</a> and <a href="privacy.html">privacy policy</a>,
                                                    and provide my express consent for this company and up to <a
                                                    href="partners.html">three third party insurance companies
                                                    or their agents or partner companies</a>
                                                    to contact me at the email address, landline or wireless
                                                    phone numbers that I provide even if they are listed on any
                                                    government Do-Not-Call registries,
                                                    in order to send me marketing/promotional messages,
                                                    including insurance quotes related to the product or service
                                                    I am inquiring about.

                                                    I agree to receive communications via live, automated
                                                    telephone dialing system, prerecorded messages,
                                                    or text messages and acknowledge that consent is not a
                                                    condition to purchase goods or services.
                                                    Products and services offered on this page includ Medicare
                                                    Supplement, Medicare Advantage and Prescription Drug Plans
                                                    Consent provided hereunder can be revoked by emailing
                                                    support@mediquotes.us”.

                                                    Participating agents represent Medicare Advantage [HMO, PPO,
                                                    PFFS, and PDP] organizations that are contracted with
                                                    Medicare.
                                                    Enrollment depends on the plan’s contract renewal.
                                                    Participating agents do not offer every plan available in
                                                    your area.

                                                    Any information we provide is limited to those plans offer
                                                    in your area. Please contact Medicare.gov or 1–800–MEDICARE
                                                    to get information on all of your options.
                                                    Enrollment in the described plan type may be limited to
                                                    certain times of the year unless you eligible for a Special
                                                    Enrollment Period.
                                                    Telephone company may impose additional charges on
                                                    subscriber for messages (required for SMS).This is a
                                                    solicitation for insurance.
                                                    Your information will be provided to a licensed agent for
                                                    future contact.]</label>
                                            </p>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-xs-12">
                                            <div className="help-block with-errors"></div>
                                            <button type="button" onClick={handleSubmit}
                                                    className="btn btn-common btn-form-submit disabled"
                                                    style={{
                                                        pointerEvents: "all",
                                                        cursor: "pointer",
                                                        marginBottom: "10px"
                                                    }}>Get no obligation Quote
                                            </button>
                                            <div id="ajaxResponse" className=""></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section id="features" className="section" style={{padding: "40px 0 0"}}>
                <div className="container">
                    <div className="section-header">
                        <h2 className="section-title">Benefits</h2>
                        <p className="section-subtitle">Our most standardized Medicare insurance
                            plans may include the following additional benefits:</p>
                    </div>
                </div>
            </section>
            <div id="subscribe" className="section">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni-bullhorn"></i></div>
                                <div className="text-box">
                                    <h4>Compare Medicare Wellbeing Plans From Diverse Protections Companies.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni-book"></i></div>
                                <div className="text-box">
                                    <h4>Medicare Advantage plan with benefits.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni-timer"></i></div>
                                <div className="text-box">
                                    <h4>Medicare Advantage Plans may help cover out-of-pocket costs.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni lni-layers"></i></div>
                                <div className="text-box">
                                    <h4>Simple, No-Obligation Quotes No Individual Contact Data Required.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni lni-briefcase"></i></div>
                                <div className="text-box">
                                    <h4>Helping beneficiaries to manage certain chronic conditions.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div className="sub-item-box">
                                <div className="icon-box"><i className="lni lni-stats-up"></i></div>
                                <div className="text-box">
                                    <h4>Plans designed for beneficiaries for long periods of institutional care.</h4>
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="call-action section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <h3>Medicare Advantage</h3>
                            <p>With a Medicare Advantage Plan, you may have coverage for things Original Medicare
                                doesn't cover, like fitness programs (gym memberships or discounts) and some vision,
                                hearing, and dental services (like routine check ups or cleanings). Plans can also
                                choose to cover even more benefits.</p>
                        </div>
                        <div className="col-10">
                            <h3>Medicare Supplement</h3>
                            <p>Medigap plans cover all or some of the following costs, with a few exceptions:<br/>
                                Medicare Part A coinsurance and hospital costs up to an additional 365 days after
                                Medicare benefits are exhausted<br/>
                                Medicare Part B coinsurance or copayment<br/>
                                Blood (first three pints)<br/>
                                Medicare Part A hospice care coinsurance or copayment<br/>
                                Skilled nursing facility care coinsurance (Plans A and B do not cover)<br/>
                                Medicare Part A deductible (Plan A does not cover)<br/>
                                Medicare Part B deductible<br/>
                                Medicare Part B excess charge (coverage only available in Medigap Plans F and G)<br/>
                                Foreign travel exchange (coverage not available in Medigap Plans A, B, K and L)<br/>
                                Medigap Plans K and L pay 100% of covered services for the rest of the calendar year
                                after meeting the out-of-pocket limit and paying your Medicare Part B deductible.</p>
                        </div>
                    </div>
                    <div className="clearfix" style={{marginTop: "100px"}}><br/>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <div className="cta-trial text-left">
                                <h3>What is Medicare Advantage Plans?</h3>
                                <p>Medicare Advantage plans often include prescription drug coverage and other benefits
                                    in addition to what's covered under Original Medicare.</p>
                                <h3>Who is eligible for Medicare Advantage Insurance?</h3>
                                <p>If you are new to Medicare, aging in, or recently moved, Medicare Advantage Plans are
                                    sold by private insurance companies. These plans work alongside your original
                                    Medicare benefits (Part A and Part B). The right Medicare Advantage Plans could may
                                    help to reduce out-of-pocket healthcare costs.</p>
                                <h3>What is Medicare Advantage Insurance?</h3>
                                <p>Medicare Advantage Plans are offered by Medicare-approved private companies that must
                                    follow rules set by Medicare. These plans set a limit on what you’ll have to pay
                                    out-of-pocket each year for covered services. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="divider-line"
                 style={{width: '100%', float: 'left', borderBottom: '#f3f3f3 2px solid'}}>
                <div className="divider-line2"
                     style={{margin: "0 auto -2px auto", width: "75px", borderBottom: "2px solid #f97794"}}></div>
            </div>
            <section className="call-action section" style={{padding: "40px 0"}}>
                <div className="container">
                    <div className="row justify-content">
                        <div className="col-12">
                            <div className="cta-trial text-left">
                                <p style={{marginBottom: "0px"}}>Medigap.us is not an insurance or operating company but
                                    connects individuals with insurance providers. Products and services are provided
                                    exclusively by the providers. Descriptions are for informational purposes only and
                                    subject to change. Insurance plans may not be available in all states. For a
                                    complete description, email us to determine eligibility and to request a copy of the
                                    applicable policy. Mediquotes.us is not affiliated with any insurance plan nor does
                                    it represent or endorse any plan. Mediquotes.us is not affiliated with or endorsed
                                    by the United States government or the federal Medicare program . By using this
                                    site, you acknowledge that you have read and agree to the Terms of Service and
                                    Privacy Policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div id="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="site-info float-left">
                                    <p>MULTI-PLAN_SQqWBtGtpu2023_C - All copyrights reserved © 2023 - Designed &
                                        Developed by <a href="index.html" rel="nofollow">Mediquotes</a></p>
                                </div>
                                <div className="float-right">
                                    <ul className="nav nav-inline">
                                        <li className="nav-item"><a className="nav-link active" href="privacy.html">Privacy
                                            Policy</a></li>
                                        <li className="nav-item"><a className="nav-link" href="partners.html">Business
                                            Partners</a></li>
                                        <li className="nav-item"><a className="nav-link" href="termsofservices.html">Terms
                                            of
                                            Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="back-to-top"> <i className="lni-arrow-up"></i> </a>
        </>
    );
}

export default App;
